export default {
    white: "#FFFFFF",
    black: "#000000",
    orange: "#EF4F1F",
    green: "#6A6358",
    neutral1: "#F5F3EE",
    neutral2: "#E7E3D7",
    warm1: "#F9F4F1",
    warm2: "#F2ECE7",
    cool1: "#E8EDEA",
    cool2: "#D7D8D3"
}